var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"active-modules"},[_c('page-title',[_c('a-button',{attrs:{"slot":"custom-button","icon":"arrow-left","type":"link"},on:{"click":_vm.gotoActiveModules},slot:"custom-button"},[_vm._v(" "+_vm._s(_vm.$t('activemodules_page_title'))+" ")])],1),_c('pi-tabs',{staticClass:"justify-center md:justify-start",attrs:{"current":_vm.currentGroup,"tabs":_vm.moduleGroups,"can-uncheck":"","multiline":""},on:{"update:current":function($event){_vm.currentGroup=$event},"tab-click":_vm.onTabChange}}),_c('div',{staticClass:"flex justify-end"},[_c('search-text-input',{staticClass:"mt-0 mb-0 w-full sm:w-1/2",attrs:{"setup":{
        'func': _vm.modelSetter,
        'args': {
          'model': this,
          'key': 'searchQuery',
          'prefix': 'modules_catalog_',
          'titleVisible': false,
          'placeholder': this.$t('anchor_search'),
          'allowClear': true
        },
      }}})],1),(_vm.queriedAllModules.length)?_c('a-row',{staticClass:"mt-1",attrs:{"type":"flex","gutter":[16,16]}},_vm._l((_vm.queriedAllModules),function(module,index){return _c('a-col',{key:index,attrs:{"xs":24,"sm":12,"md":24,"lg":12,"xl":8}},[_c('module-card',{attrs:{"loading":_vm.moduleLoading === module.model.guid,"module-builder":module},scopedSlots:_vm._u([{key:"actions",fn:function(){return [_c('a',{staticClass:"select-none",on:{"click":function($event){$event.preventDefault();return _vm.handleOnAdd(module)}}},[_c('a-icon',{staticClass:"text-success",attrs:{"type":"plus"}}),_vm._v(" "+_vm._s(_vm.$t('module_add_button_title'))+" ")],1),(_vm.$te(module.model.descriptionKey))?_c('div',{on:{"click":function($event){_vm.activeModule = module; _vm.aboutModuleIsOpen = true}}},[_c('help-message',{staticClass:"inline align-bottom",attrs:{"title":_vm.$t('module_about_button_title'),"help-message":_vm.$t(module.model.descriptionKey)}})],1):_vm._e()]},proxy:true}],null,true)})],1)}),1):_c('empty-data',{staticClass:"mt-5"})],1)}
var staticRenderFns = []

export { render, staticRenderFns }