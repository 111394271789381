





























































































import { ModuleGroupEnum } from '@/includes/logic/Modules/types/types'
import { groupsSelectOptions } from '@/includes/logic/Modules/ModulesGroupsUtil'
import ModuleBuilder from '@/includes/logic/Modules/Builders/ModuleBuilder'
import ModuleCard from '@/components/Modules/components/ModuleCard.vue'
import ModulesListMixin from "@/includes/logic/Modules/mixins/ModulesListMixin";

import EmptyData from 'piramis-base-components/src/components/EmptyData/EmptyData.vue'
import PageTitle from 'piramis-base-components/src/components/PageTitle.vue'
import { FieldData } from "piramis-base-components/src/components/Pi/types";
import SearchTextInput from 'piramis-base-components/src/components/Pi/fields/SearchTextInput/SearchTextInput.vue';
import HelpMessage from 'piramis-base-components/src/components/HelpMessage/HelpMessage.vue'
import PiTabs from "piramis-base-components/src/components/PiTabs.vue";

import { Component, Mixins } from 'vue-property-decorator'

@Component({
  components: {
    PiTabs,
    ModuleCard,
    PageTitle,
    EmptyData,
    SearchTextInput,
    HelpMessage
  },
})
export default class ModulesCatalog extends Mixins(ModulesListMixin) {
  activeModule: null | ModuleBuilder = null

  aboutModuleIsOpen = false

  get moduleGroups() {
    return groupsSelectOptions
  }

  get queriedAllModules() {
    return this.queriedModules()
  }

  handleOnAdd(module: ModuleBuilder) {
    this.onAddModule(module)
  }

  onTabChange(tab: ModuleGroupEnum | null) {
    const name = this.isChatManagerVm ? 'ModulesCatalog' : 'NetworkModulesCatalog'

    this.$router.replace({
      name,
      ...tab ? {
        params: { tab }
      } : {}
    })
  }

  modelSetter(args: FieldData) {
    args.setter = (value: string) => {
      args.model[args.key] = value.trim()
    }

    return args
  }

  mounted() {
    this.$baseTemplate.saveButton.hide()
  }

  destroyed() {
    this.$baseTemplate.saveButton.show()
  }
}
